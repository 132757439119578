<template>
  <div class="card incident-card me-3">
    <div class="card-header justify-content-between">
      <h3 class="card-title">#{{ incident.incidentid }}</h3>
      <span class="text-muted">{{ niceDate(incident.createdate) }}</span>
    </div>
    <div class="card-body">
      <p class="mb-0 pb-0" v-if="site">
        <a :href="`https://${site.directoryurl}`">
          {{ site.fullsitename }}
        </a>
        <span class="badge bg-orange" v-if="!site.isactive">INACTIVE</span>
      </p>

      <p class="mb-0 pb-0" v-else>No site</p>

      <p class="mb-0 pb-0">Sold By {{ accountExecutive.fullname }}</p>
      <div
        class="d-flex align-items-center"
        v-if="hasIo && insertionOrder.insertionOrderItems"
      >
        <span
          :class="[
            'me-1',
            insertionOrder.confirmdate ? 'text-green' : 'text-red',
          ]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-cash-banknote"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="12" cy="12" r="3"></circle>
            <rect x="3" y="6" width="18" height="12" rx="2"></rect>
            <line x1="18" y1="12" x2="18.01" y2="12"></line>
            <line x1="6" y1="12" x2="6.01" y2="12"></line>
          </svg>
          {{ money(insertionOrder.totalamount) }}
        </span>
        <span
          v-if="insertionOrder.installmentquantity > 1"
          class="badge bg-green me-1"
        >
          {{ insertionOrder.installmentquantity }} INSTALLMENTS</span
        >
        <span v-else class="badge bg-gray me-1"> Single</span>
        <span v-if="insertionOrder.donotrenew" class="badge bg-red me-1">
          Not Renewable
        </span>
        <span v-else class="badge bg-green me-1">Renewable</span>
        <span v-if="insertionOrder.confirmdate" class="text-muted small">
          Paid in {{ timeToPay }}
        </span>
      </div>
    </div>

    <div
      class="card-table table-responsive"
      v-if="hasIo && insertionOrder.insertionOrderItems"
    >
      <table class="table">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredIoItems"
            :key="`incident-${incident.incidentid}-io-item-${item.id}`"
          >
            <td>{{ item.itemName }}</td>
            <td>{{ money(item.amount) }}</td>
          </tr>
          <tr
            v-if="
              insertionOrder.insertionOrderItems.length -
                filteredIoItems.length >
                0 || showFree
            "
          >
            <td colspan="2">
              <a
                v-if="!showFree"
                class="small"
                href="#"
                @click.prevent="showFree = true"
              >
                Show
                {{
                  insertionOrder.insertionOrderItems.length -
                  filteredIoItems.length
                }}
                hidden items
              </a>
              <a
                v-else
                class="small"
                href="#"
                @click.prevent="showFree = false"
              >
                Hide free items
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.incident-card {
  max-width: 36rem;
  min-width: 36rem;
}
</style>

<script>
import SiteService from "../../../../services/SiteService";
import AccountExecutiveService from "../../../../services/AccountExecutiveService";
import InsertionOrderService from "../../../../services/InsertionOrderService";

import { parseISO, format, formatDistance } from "date-fns";

export default {
  name: "admin.insights.sales.incident",
  props: {
    incident: Object,
  },
  data() {
    return {
      site: {},
      accountExecutive: {},
      insertionOrder: {},
      showFree: false,
    };
  },
  computed: {
    hasIo() {
      return this.incident.salesprogressid == 8 && this.insertionOrder;
    },
    filteredIoItems() {
      if (!this.hasIo || !this.insertionOrder.insertionOrderItems) {
        return [];
      }
      return this.insertionOrder.insertionOrderItems.filter(
        (ioi) => ioi.amount > 0 || this.showFree
      );
    },
    timeToPay() {
      try {
        return formatDistance(
          parseISO(this.insertionOrder.createdate),
          parseISO(this.insertionOrder.confirmdate)
        );
      } catch (e) {
        return "Not paid.";
      }
    },
  },
  methods: {
    niceDate(uglyDate) {
      return format(parseISO(uglyDate), "MM/dd/yyyy");
    },
    money(money) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(money);
    },
  },
  async mounted() {
    if (this.incident.siteid) {
      this.site = await SiteService.getSite(this.incident.siteid);
    } else {
      this.site = null;
    }

    this.accountExecutive = await AccountExecutiveService.getAccountExecutive(
      this.incident.repid
    );

    this.insertionOrder =
      await InsertionOrderService.getInsertionOrderForIncident(
        this.incident.incidentid
      );
  },
};
</script>
