import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getAccountExecutive(accountExecutiveId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/accountexecutive/id/${accountExecutiveId}`,
      authHeaders
    );
    return res.data;
  },
  async getAllAE() {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(`api/accountexecutive/all`, authHeaders);
    return res.data;
  },
};
