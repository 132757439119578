<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3>Company MAC Usage</h3>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-8">
        <div class="card">
          <div class="card-table table-responsive max-height-table">
            <table class="table">
              <thead>
                <tr>
                  <th>Page Name</th>
                  <th>Page URL</th>
                  <th>Visit Date</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody v-if="pageViews.length > 0">
                <tr
                  v-for="(pageview, idx) in pageViews"
                  :key="`pageview-${idx}`"
                >
                  <td>{{ pageview.pageName }}</td>
                  <td>
                    <a :href="pageview.pageUrl" target="_blank">
                      {{ pageview.pageUrl }}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-external-link"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                        ></path>
                        <line x1="10" y1="14" x2="20" y2="4"></line>
                        <polyline points="15 4 20 4 20 9"></polyline>
                      </svg>
                    </a>
                  </td>
                  <td>{{ niceDate(pageview.pageViewDate) }}</td>
                  <td>{{ pageviewContact(pageview) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4">
                    <p class="text-muted pb-0 mb-0 text-center">
                      No pageviews in time range.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-4" v-if="activity.activeContacts">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Active Contacts</h3>
          </div>

          <div class="card-body">
            <ul v-if="activity.activeContacts.length > 0">
              <li
                v-for="contact in activity.activeContacts"
                :key="`contact-${contact.contactid}`"
              >
                {{ contact.prefix }} {{ contact.firstname }}
                {{ contact.lastname }}
                <a :href="`mailto:${contact.emailaddress}`">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-mail"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="3" y="5" width="18" height="14" rx="2"></rect>
                    <polyline points="3 7 12 13 21 7"></polyline>
                  </svg>
                </a>
              </li>
            </ul>
            <p class="text-muted" v-else>
              No active contacts for this time range.
            </p>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <p class="pb-0 mb-0">
              <b>{{ totalPageViews }}</b> page views.
            </p>
            <p class="pb-0 mb-0">
              Last active <b>{{ lastActive }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.max-height-table {
  max-height: 320px;
  overflow: auto;

  thead {
    position: sticky;
    top: 0;
  }
}
</style>

<script>
import AccountInsightsService from "@/services/AccountInsightsService";
import { parseISO, format, formatDistanceToNow } from "date-fns";

export default {
  name: "admin.insights.usage-stats",
  props: {
    account: Object,
  },
  data() {
    return {
      activity: {},
    };
  },
  computed: {
    pageViews() {
      if (!this.activity.pageViews) {
        return [];
      }
      return this.activity.pageViews.filter((pv) =>
        pv.pageUrl.startsWith("https://mac.")
      );
    },
    lastActive() {
      if (this.pageViews.length == 0) return "never.";
      return (
        formatDistanceToNow(parseISO(this.pageViews[0].pageViewDate)) + " ago."
      );
    },
    totalPageViews() {
      return this.pageViews.length;
    },
  },
  methods: {
    pageviewContact(pageview) {
      let contact = this.activity.activeContacts.filter(
        (c) => c.contactid == pageview.contact
      )[0];

      return `${contact.firstname} ${contact.lastname}`;
    },
    niceDate(uglyDate) {
      return format(parseISO(uglyDate), "MM/dd/yyyy");
    },
  },
  watch: {
    async account() {
      this.activity = await AccountInsightsService.getActivityInsights(
        this.account
      );
    },
  },
  async mounted() {
    this.activity = await AccountInsightsService.getActivityInsights(
      this.account
    );
  },
};
</script>
