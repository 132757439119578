<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Admin: Account Insights</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col-3">
          <date-select v-model="dates"></date-select>
        </div>
      </div>

      <usage-stats :account="account"></usage-stats>

      <whitepaper-stats :account="account"></whitepaper-stats>

      <sales-journey :account="account"></sales-journey>
    </div>
  </div>
</template>

<script>
import UsageStats from "../../components/Admin/Insights/UsageStats.vue";
import WhitepaperStats from "../../components/Admin/Insights/WhitepaperStats.vue";
import DateSelect from "../../components/Reports/VisitorInsight/DateSelect.vue";
import SalesJourney from "../../components/Admin/Insights/SalesJourney.vue";

import { subDays } from "date-fns";

export default {
  name: "admin.insights",
  components: { WhitepaperStats, UsageStats, DateSelect, SalesJourney },
  data() {
    return {
      dates: [subDays(new Date(), 30), new Date()],
    };
  },
  computed: {
    account() {
      if (this.dates.length != 2) {
        return {
          company: this.$auth.activeCompany,
          startDate: subDays(new Date(), 30),
          endDate: new Date(),
        };
      }
      return {
        company: this.$auth.activeCompany,
        startDate: this.dates[0],
        endDate: this.dates[1],
      };
    },
  },
  methods: {},
  async mounted() {
    if (!this.$auth.superuser) {
      this.$router.replace("/");
    }
  },
};
</script>
