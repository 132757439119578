<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3>Whitepaper Stats</h3>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-9">
        <div class="wp-cards d-flex">
          <div
            v-for="whitepaper in whitepaperStats"
            :key="`whitepaper-${whitepaper.whitePaper.whitePaperId}`"
            class="card wp-stats-card me-3"
          >
            <div class="card-header">
              <h3 class="card-title">
                {{ whitepaper.whitePaper.title }}
              </h3>
            </div>

            <div class="card-table wp-lead-table">
              <table class="table mb-0" v-if="whitepaper.leads.length > 0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lead in whitepaper.leads"
                    :key="`wp-${whitepaper.whitePaper.whitePaperId}-lead-${lead.feLeadId}`"
                  >
                    <td>
                      {{ shortName(lead) }}
                    </td>
                    <td>{{ lead.leadEmailaddress }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <p class="p-0 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-calendar-plus"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                    <line x1="16" y1="3" x2="16" y2="7"></line>
                    <line x1="8" y1="3" x2="8" y2="7"></line>
                    <line x1="4" y1="11" x2="20" y2="11"></line>
                    <line x1="10" y1="16" x2="14" y2="16"></line>
                    <line x1="12" y1="14" x2="12" y2="18"></line>
                  </svg>
                  {{ niceDate(whitepaper.whitePaper.createdate) }}
                </p>
                <p class="p-0 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="2"></circle>
                    <path
                      d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                    ></path>
                  </svg>
                  {{ whitepaper.views }}
                </p>

                <p class="p-0 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-users"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                  </svg>
                  {{ whitepaper.leads.length }}
                </p>

                <p class="p-0 m-0">
                  <a
                    :href="`${whitepaper.whitepaperDownloadUrl}${whitepaper.whitePaper.filename}`"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-link"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"
                      ></path>
                      <path
                        d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"
                      ></path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card">
          <div class="card-table">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <b>{{ total_whitepapers }}</b> whitepapers uploaded
                  </td>
                </tr>
                <tr>
                  <td>
                    Last uploaded <b>{{ last_whitepaper_uploaded }}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{{ total_leads }}</b> leads
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{{ total_views }}</b> total views
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wp-cards {
  overflow: auto;
  flex-wrap: nowrap;
}
.wp-stats-card {
  max-width: 24rem;
  min-width: 24rem;

  .wp-lead-table {
    max-height: 100px;
    overflow: auto;
  }
}
</style>

<script>
import AccountInsightsService from "@/services/AccountInsightsService";
import { parseISO, format, formatDistanceToNow } from "date-fns";

export default {
  name: "admin.insights.whitepaper-stats",
  props: {
    account: Object,
  },
  data() {
    return {
      loaded: false,
      whitepaperStats: [],
    };
  },
  computed: {
    total_whitepapers() {
      return this.whitepaperStats.length;
    },
    total_leads() {
      return this.whitepaperStats.reduce(
        (total, current) => total + current.leads.length,
        0
      );
    },
    total_views() {
      return this.whitepaperStats.reduce(
        (total, current) => total + current.views,
        0
      );
    },
    last_whitepaper_uploaded() {
      if (this.total_whitepapers > 0) {
        return (
          formatDistanceToNow(
            parseISO(this.whitepaperStats[0].whitePaper.createdate)
          ) + " ago"
        );
      } else {
        return "never";
      }
    },
  },
  watch: {
    async account() {
      this.whitepaperStats = await AccountInsightsService.getWhitepaperInsights(
        this.account
      );
    },
  },
  methods: {
    shortName(lead) {
      let name = "";
      if (lead.leadFirstName) {
        name += lead.leadFirstName;
      }

      if (lead.leadLastName) {
        name += lead.leadLastName.substr(0, 1);
      }
      return name;
    },
    niceDate(uglyDate) {
      return format(parseISO(uglyDate), "MM/dd/yyyy");
    },
  },
  async mounted() {
    this.loaded = true;
    this.whitepaperStats = await AccountInsightsService.getWhitepaperInsights(
      this.account
    );
  },
};
</script>
