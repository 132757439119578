<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3>Sales Journey</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex incidents">
          <incident
            :incident="incident"
            v-for="incident in incidents"
            :key="`incident-${incident.incidentid}`"
          ></incident>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.incidents {
  flex-wrap: nowrap;
  overflow: auto;
}
</style>

<script>
import AccountInsightsService from "@/services/AccountInsightsService";
import Incident from "./Sales/Incident.vue";

export default {
  components: { Incident },
  name: "admin.insights.sales-journey",
  props: {
    account: Object,
  },
  data() {
    return {
      loaded: false,
      incidents: [],
    };
  },
  computed: {},
  watch: {
    async account() {
      this.incidents = await AccountInsightsService.getNotableIncidents(
        this.account
      );
    },
  },
  methods: {},
  async mounted() {
    this.incidents = await AccountInsightsService.getNotableIncidents(
      this.account
    );
    this.loaded = true;
  },
};
</script>
