<template>
  <div>
    <div class="subheader mb-2">Date Range</div>
    <div class="mb-3">
      <select
        class="form-select mb-2"
        v-model="selectedRange"
        @change="changedDate"
      >
        <option value="30-days">Last 30 days</option>
        <option value="this-year">This year so far</option>
        <option value="last-year">Last year</option>
        <option value="all-time">All Time</option>
        <option value="custom">Custom</option>
      </select>

      <div v-if="selectedRange == 'custom'">
        <div class="subheader">Start Date</div>

        <b-form-datepicker
          ref="startDate"
          v-model="start"
          class="mb-2"
          :value-as-date="true"
          @hidden="updateCustomDate"
        ></b-form-datepicker>

        <div class="subheader">End Date</div>

        <b-form-datepicker
          ref="endDate"
          v-model="end"
          :min="start"
          class="mb-2"
          :value-as-date="true"
          @hidden="updateCustomDate"
        ></b-form-datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import { subDays, startOfYear, subYears } from "date-fns";

export default {
  name: "reports.visitor-insight.date-select",
  props: {
    suggestedRange: String,
  },
  data() {
    let suggested = this.suggestedRange;
    if (!suggested) {
      suggested = "30-days";
    }
    return {
      selectedRange: suggested,
      start: null,
      end: null,
    };
  },
  watch: {
    suggestedRange() {
      console.log("updated");
      if (this.selectedRange != this.suggestedRange) {
        this.selectedRange = this.suggestedRange;
        this.changedDate();
      }
    },
  },
  methods: {
    updateCustomDate() {
      if (this.start != null && this.end != null) {
        this.$emit("input", [this.start, this.end]);
      }
    },
    changedDate() {
      console.log("date change", this.selectedRange);
      if (this.selectedRange == "30-days") {
        this.start = subDays(new Date(), 30);
        this.end = new Date();
      } else if (this.selectedRange == "this-year") {
        this.start = startOfYear(new Date());
        this.end = new Date();
      } else if (this.selectedRange == "last-year") {
        this.start = subYears(startOfYear(new Date()), 1);
        this.end = startOfYear(new Date());
      } else if (this.selectedRange == "all-time") {
        this.start = new Date(2000, 1, 1);
        this.end = new Date();
      } else {
        this.start = null;
        this.end = null;
        this.$nextTick(() => {
          this.$refs.startDate.focus();
        });
      }
      let n = [];
      if (this.start != null && this.end != null) {
        n.push(this.start);
        n.push(this.end);
      }
      this.$emit("input", n);
    },
  },
};
</script>
