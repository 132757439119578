import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getWhitepaperInsights(insights) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/AccountInsights/whitepaper-insights",
      insights,
      authHeaders
    );
    return res.data;
  },
  async getActivityInsights(insights) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/AccountInsights/activity",
      insights,
      authHeaders
    );
    return res.data;
  },
  async getNotableIncidents(insights) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/AccountInsights/incidents",
      insights,
      authHeaders
    );
    return res.data;
  },
};
